@import "@mantine/core/styles.css";

/* MAPLIBRE */
@import 'maplibre-gl/dist/maplibre-gl.css';

@import '../../utils/fonts/Inter.css';

.rootshell-menu-button {
    width: calc(1.125rem * var(--mantine-scale));
    height: calc(1.125rem * var(--mantine-scale));
}

.rootshell-submenu-button {
    width: calc(0.875rem * var(--mantine-scale));
    height: calc(0.875rem * var(--mantine-scale));
}